.products-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.products-title {
    text-align: center;
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}

.product-form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 20px;
}

.product-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.product-download {
    border: rgba(145,145,145,0.22) 2px solid;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
}


.product-download label {
    width: 5%;
    display: inline-block;
}


.product-download label.section-label {
    width: fit-content;
    margin-top: 5px;
    margin-bottom: 5px;
    display: block;
    padding: 5px;
}

.product-download label.section-label,
.product-download input {
    display: inline-block;
}

.product-download input {
    width: 95%;
}

.product-download.samples input {
    width: 90%;
}

.product-download.samples label {
    width: 10%;
    display: inline-block;
}

.product-download.samples label.section-label {
    width: fit-content;
}

.product-download.release label.section-label {
    color: white;
    background-color: green;
}

.product-download.release input {
    background-color: #bbecc0;
}

.product-download.prerelease input {
    background-color: lightyellow;
}

.product-download.prerelease label.section-label {
    color: black;
    background-color: yellow;
}


.product-download.samples label.section-label {
    color: white;
    background-color: brown;
}

.error-message {
    color: #ff6b6b;
    margin-bottom: 10px;
}

.form-actions {
    text-align: right;
}

.submit-btn, .cancel-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
}

.submit-btn {
    background-color: #4CAF50;
    color: white;
}

.cancel-btn {
    background-color: #f44336;
    color: white;
}

.product-list {
    list-style: none;
    padding: 0;
}

.product-item {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-actions button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 5px;
}

.edit-btn {
    background-color: #ffc107;
    color: white;
}

.delete-btn {
    background-color: #dc3545;
    color: white;
}

.go-back-link {
    display: block;
    text-align: center;
    margin-top: 20px;
    color: #007bff;
    text-decoration: none;
}

.no-wrap {
    white-space: nowrap;
}